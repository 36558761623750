import { OpenAI } from "openai";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

export default class GPTGenerator {
    private static bot = new OpenAI({
        apiKey: environment.openai_api_key,
        dangerouslyAllowBrowser: true,
    });

    public static async generateText(
        prompt: string,
        history?: OpenAI.Chat.Completions.ChatCompletionMessageParam[]
    ): Promise<OpenAI.Chat.Completions.ChatCompletion> {
        return this.bot.chat.completions.create({
            messages: [...(history ?? []), { role: "user", content: prompt }],
            model: "gpt-4o",
            temperature: 0.8,
            max_tokens: 80,
        });
    }

    public static async generateImage(
        prompt: string
    ): Promise<OpenAI.Images.ImagesResponse> {
        return this.bot.images.generate({
            prompt,
            model: "dall-e-3",
            size: "1024x1024",
            response_format: "b64_json",
            n: 1,
        });
    }
}
