import React from "react";
import { Box, useTheme } from "@mui/material";

const Bg = () => {
    const theme = useTheme();

    return (
        <Box
            position="fixed"
            width="100vw"
            height="100vh"
            top={0}
            left={0}
            zIndex={-10}
            sx={{
                background: `linear-gradient(180deg, #FFFFFF00 0%, #3758F900 53%, ${theme.palette.primary.main} 100%)`,
                opacity: "0.15",
            }}
        />
    );
};

export default Bg;
