export interface DefaultPrompt {
    tech: string;
    display: string;
}

// Prompt for generating a business name
export const BUSINESS_NAME_PROMPT = (
    businessType: string,
    language: string
) => {
    const techPrompt = `
Generate a single, creative business name for a small business with 1 to 10 employees, specializing in ${businessType} in ${language}-speaking region. 
The name should:
- Be less than 10 letters,
- Evoke a sense of creativity and distinctiveness,
- Generate a list of at least 10 varied name ideas before selecting one to ensure a wide range of possibilities,
- Aim for originality and creativity in each response to maximize diversity among results from different users.

Return only the name with no additional text or explanation.
`;
    const displayText = "Generate me a unique and fancy name for my business";

    return {
        tech: techPrompt,
        display: displayText,
    } as DefaultPrompt;
};
