import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

export default class ImageLoader {
    public static readonly insert = async (image: string) =>
        axios.put(environment.api_url + "/image/insert", {
            data: image,
        });

    public static readonly format = (link: string) =>
        `${environment.api_url}/image/${link}`;
}
