import { useEffect } from "react";
import { getEnvironment } from "../utils/getEnvironment";

declare global {
    interface Window {
        Weglot?: {
            initialize: (config: {
                api_key: string;
                auto_switch: boolean;
                wait_transition?: boolean;
                extra_definitions?: Array<{
                    type: number;
                    selector: string;
                    attribute: string;
                }>;
            }) => void;
            website: string;

            options: {
                languages: Array<{
                    language_from: string; // Original language code
                    language_to: string; // Target language code
                }>;
                language_from: string; // Original language code
            };
            getCurrentLang: () => string; // Method to get the current language
            getLanguageName: (lang: string) => string; // Method to get the language name based on the language code
            switchTo: (lang: string) => void; // Method to switch the language
            on: (event: string, callback: (lang: string) => void) => void; // Event listener for language changes
            getBestAvailableLanguage: () => String;
        };
    }
}

const environment = getEnvironment();

const useWeglot = () => {
    useEffect(() => {
        if (window.Weglot) {
            window.Weglot.initialize({
                api_key: environment.weglot_api_key,
                auto_switch: true,
                wait_transition: true,
            });
        }
    }, []);
};

export default useWeglot;
