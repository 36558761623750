import React, { useState } from "react";
import { Stack, Modal } from "@mui/material";
import ImagePromptModal from "./ImagePromptModal";
import Button from "./Button";
import AIIcon from "../img/AI.svg";
import FolderIcon from "../img/Folder.svg";
import { useFilePicker } from "use-file-picker";
import {
    FileAmountLimitValidator,
    FileTypeValidator,
    FileSizeValidator,
} from "use-file-picker/validators";

interface Props {
    onUpload: (val?: string) => void;
}

const ImagePicker = (props: Props) => {
    const [openModal, setOpenModal] = useState(false);

    const { onUpload } = props;

    const onCancel = () => {
        setOpenModal(false);
    };

    const onSave = (val?: string) => {
        onCancel();
        if (!val) return;
        onUpload(val);
    };

    const { openFilePicker, loading } = useFilePicker({
        readAs: "DataURL",
        accept: "image/*",
        multiple: false,
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(["jpeg", "jpg", "png"]),
            new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 }), // 10 MB
        ],
        onFilesSelected: ({ errors }) => {
            if (errors) {
                console.error("File upload error:", errors);
            }
        },
        onFilesSuccessfullySelected: ({ filesContent }) => {
            const item = filesContent[0];
            if (item) {
                onUpload(item.content);
            }
        },
    });

    return (
        <Stack spacing={2} alignItems="center">
            <Button
                variant="medium"
                color="transparent"
                icon={FolderIcon}
                onClick={openFilePicker}
                disabled={loading}
                sx={{
                    width: "100%",
                    maxWidth: "200px",
                    fontSize: "1rem",
                    fontWeight: 500,
                    textTransform: "none",
                }}
            >
                Upload Icon
            </Button>
            <Button
                variant="medium"
                color="primary"
                icon={AIIcon}
                onClick={() => setOpenModal(true)}
                disabled={loading}
                sx={{
                    width: "100%",
                    maxWidth: "200px",
                    fontSize: "1rem",
                    fontWeight: 500,
                    textTransform: "none",
                }}
            >
                AI Generate
            </Button>
            <Modal open={openModal} onClose={() => {}}>
                <ImagePromptModal onCancel={onCancel} onSave={onSave} />
            </Modal>
        </Stack>
    );
};

export default ImagePicker;
