import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

interface GoogleResponse {
    token: string;
    id: string;
    email: string;
    verified_email: boolean;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    locale: string;
}

export default class Account {
    public static readonly signUp = async (
        name: string,
        email: string,
        password?: string
    ) =>
        (
            await axios
                .post(environment.api_url + "/auth/signup", {
                    name,
                    email,
                    password,
                })
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)
        )?.key as string | undefined;

    public static readonly googleSignUp = async (token: string) => {
        const data = await axios
            .get(
                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                    },
                }
            )
            .then((res) => res.data as GoogleResponse)
            .catch((err) => console.error(err));
        if (!data) return;
        return (
            await axios
                .post(environment.api_url + "/auth/signup", {
                    email: data.email,
                    token,
                })
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)
        )?.key as string | undefined;
    };
}
