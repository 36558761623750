export const getEnvironment = () => {
    // CRA is using .env automatically for local builds

    // Check that variables are loaded
    if (
        !process.env.REACT_APP_OPENAI_API_KEY ||
        !process.env.REACT_APP_GOOGLE_CLIENT_ID ||
        !process.env.REACT_APP_WEGLOT_API_KEY ||
        !process.env.REACT_APP_API_URL ||
        !process.env.REACT_APP_PREVIEW_ADDRESS ||
        !process.env.REACT_APP_ADMIN_ADDRESS
    ) {
        throw new Error("Missing required environment variables");
    }

    return {
        openai_api_key: process.env.REACT_APP_OPENAI_API_KEY,
        google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        weglot_api_key: process.env.REACT_APP_WEGLOT_API_KEY,
        api_url: process.env.REACT_APP_API_URL,
        preview_address: process.env.REACT_APP_PREVIEW_ADDRESS,
        admin_address: process.env.REACT_APP_ADMIN_ADDRESS,
    } as const;
};
