import React from "react";

interface Props {
    icon: string;
    color?: string;
    size?: number | string;
    style?: React.CSSProperties;
}

const ColoredIcon = (props: Props) => {
    const { icon, size, style, color } = props;

    return (
        <div
            style={{
                backgroundColor: color,
                maskImage: `url(${icon})`,
                maskRepeat: "no-repeat",
                maskPosition: "center",
                maskSize: size,
                width: size,
                height: size,
                ...style,
            }}
        ></div>
    );
};

export default ColoredIcon;
