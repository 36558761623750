// TRANSLATIONS TO REVIEW

import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import ProgressHeader from "./ProgressHeader";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Button from "../Button";
import ColoredIcon from "../ColoredIcon";
import Color from "../../modules/Color";
import DefaultIcon from "../../img/default_icon.svg";
import GoogleIcon from "../../img/Google.svg";
import Account from "../../api/Account";
import Brand from "../../api/Brand";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import TextField from "../TextField";
import PasswordField from "../PasswordField";
import { useCookies } from "react-cookie";
import Bg from "../Bg";

const SignUp = () => {
    const [token, setToken] = useState<TokenResponse | undefined>();
    const [brand, setBrand] = useState<
        | {
              name: string;
              address: string;
              color: Color;
              logo?: string;
              business: string;
          }
        | undefined
    >();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [stdRegister, setStdRegister] = useState(false);
    const [loading, setLoading] = useState(false);

    const [, setCookie] = useCookies(["creation_uuid"]);

    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const brand_loaded = sessionStorage.getItem("brand");
        if (brand_loaded === null) {
            window.location.href = "/creation/brand";
        }
        const brand_raw: {
            name: string;
            address: string;
            color: string;
            logo?: string;
            business: string;
        } = JSON.parse(brand_loaded!);
        setBrand({
            name: brand_raw.name,
            address: brand_raw.address,
            color: Color.fromJSON(brand_raw.color),
            logo: brand_raw.logo,
            business: brand_raw.business,
        });
    }, []);

    const onGoogleLoginError = () => console.error("Google login error.");
    const googleLogin = useGoogleLogin({
        onSuccess: (res) => setToken(res),
        onError: () => onGoogleLoginError(),
    });

    useEffect(() => {
        if (!token || !brand) return;
        Account.googleSignUp(token.access_token)
            .then((uuid) => {
                if (!uuid) return;
                setCookie("creation_uuid", uuid);
                Brand.register(
                    uuid,
                    brand.name,
                    brand.address,
                    brand.color,
                    "",
                    brand.business,
                    brand.logo
                )
                    .then(() => (window.location.href = "/creation/completed"))
                    .catch(console.error);
            })
            .catch(console.error)
            .finally(() => {
                setLoading(false);
            });
    }, [token, brand, setCookie]);

    const standardSignUp = () => {
        if (!brand) return;
        if (name.trim() === "" || email.trim() === "" || password === "") {
            alert("Fill all the fields.");
            return;
        }
        setLoading(true);
        Account.signUp(name, email, password)
            .then((uuid) => {
                if (!uuid) {
                    alert("Email is already used.");
                    return;
                }
                setCookie("creation_uuid", uuid);
                Brand.register(
                    uuid,
                    brand.name,
                    brand.address,
                    brand.color,
                    "",
                    brand.business,
                    brand.logo
                )
                    .then(() => (window.location.href = "/creation/completed"))
                    .catch(console.error);
            })
            .catch(console.error)
            .finally(() => {
                setLoading(false);
            });
    };

    /*
    const handleKeyDown = (
        e: React.KeyboardEvent,
        nextRef?: React.RefObject<HTMLInputElement>
    ) => {
        if (e.key === "Enter") {
            if (nextRef && nextRef.current) {
                nextRef.current.focus();
            } else standardSignUp();
        }
    };
    */

    if (!brand) return <CircularProgress />;
    if (!stdRegister)
        return (
            <Stack height="100vh" width="100%" alignItems="center">
                <Bg />
                <Header />
                <ProgressHeader step={2} title="Create Your App Account" />
                <Stack
                    width="80%"
                    alignItems="center"
                    spacing={8}
                    marginTop={8}
                >
                    <Typography
                        fontWeight={700}
                        fontSize="24px"
                        lineHeight="28.8px"
                        textAlign="center"
                    >
                        Awesome work!
                    </Typography>
                    <Stack spacing={1}>
                        <Box
                            width="96px"
                            height="96px"
                            borderRadius="22px"
                            bgcolor={brand.color.toHex()}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {brand!.logo ? (
                                <Box
                                    width="100%"
                                    height="100%"
                                    borderRadius="22px"
                                    sx={{
                                        backgroundImage: `url(${brand!.logo})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                />
                            ) : (
                                <ColoredIcon
                                    icon={DefaultIcon}
                                    size={64}
                                    color="white"
                                />
                            )}
                        </Box>
                        <Typography
                            fontWeight={500}
                            fontSize="19px"
                            lineHeight="21.28px"
                            textAlign="center"
                        >
                            {brand!.name}
                        </Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                            textAlign="center"
                            sx={{ opacity: "80%" }}
                        >
                            Now let's save the result in your app so we can keep
                            setting things up!
                        </Typography>
                        <Button
                            variant="big"
                            icon={GoogleIcon}
                            disabled={loading}
                            onClick={() => googleLogin()}
                            fullWidth
                        >
                            Sign in with Google
                        </Button>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                            textAlign="center"
                            sx={{ opacity: "80%" }}
                        >
                            If you do not have a Google account, please proceed
                            with:
                        </Typography>
                        <Button
                            variant="big"
                            color="outlined"
                            disabled={loading}
                            onClick={() => setStdRegister(true)}
                            fullWidth
                        >
                            Standard registration
                        </Button>
                    </Stack>
                </Stack>
                <Stack
                    height="100%"
                    width="80%"
                    justifyContent="flex-end"
                    alignItems="center"
                    marginBottom={6}
                    spacing={2}
                >
                    <Typography
                        fontWeight={400}
                        fontSize="10px"
                        lineHeight="15px"
                        textAlign="center"
                        sx={{ opacity: "50%" }}
                    >
                        By using our app, you agree to our terms and accept that
                        we are not responsible for any issues arising from its
                        use. For more details, please refer to the{" "}
                        <a href="#" style={{ color: "black" }}>
                            full disclaimer
                        </a>
                        .
                    </Typography>
                </Stack>
            </Stack>
        );
    else
        return (
            <Stack
                className="force-dynamic-translation"
                height="100vh"
                width="100%"
                alignItems="center"
            >
                <Bg />
                <Header />
                <ProgressHeader step={2} title="Create Your App Account" />
                <Stack
                    width="80%"
                    alignItems="center"
                    spacing={8}
                    marginTop={8}
                >
                    <Stack spacing={1}>
                        <Box
                            width="96px"
                            height="96px"
                            borderRadius="22px"
                            bgcolor={brand.color.toHex()}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {brand!.logo ? (
                                <Box
                                    width="100%"
                                    height="100%"
                                    borderRadius="22px"
                                    sx={{
                                        backgroundImage: `url(${brand!.logo})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                />
                            ) : (
                                <ColoredIcon
                                    icon={DefaultIcon}
                                    size={64}
                                    color="white"
                                />
                            )}
                        </Box>
                        <Typography
                            fontWeight={500}
                            fontSize="19px"
                            lineHeight="21.28px"
                            textAlign="center"
                        >
                            {brand!.name}
                        </Typography>
                    </Stack>
                    <Stack
                        className="force-dynamic-translation"
                        spacing={1}
                        width="100%"
                    >
                        <TextField
                            variant="contained"
                            fullWidth
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            nextRef={emailInputRef}
                        />
                        <TextField
                            variant="contained"
                            fullWidth
                            placeholder="email@gmail.com"
                            value={email}
                            onChange={(e) =>
                                setEmail(
                                    e.currentTarget.value.toLocaleLowerCase()
                                )
                            }
                            ref={emailInputRef}
                            nextRef={passwordInputRef}
                        />
                        <PasswordField
                            variant="contained"
                            fullWidth
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            ref={passwordInputRef}
                            onEnter={standardSignUp}
                        />
                    </Stack>
                    <Button
                        variant="big"
                        disabled={loading}
                        onClick={() => standardSignUp()}
                        color="black"
                        fullWidth
                    >
                        Register
                    </Button>
                </Stack>
                <Stack
                    height="100%"
                    width="80%"
                    justifyContent="flex-end"
                    alignItems="center"
                    marginBottom={6}
                    spacing={2}
                >
                    <Typography
                        fontWeight={400}
                        fontSize="10px"
                        lineHeight="15px"
                        textAlign="center"
                        sx={{ opacity: "50%" }}
                    >
                        By using our app, you agree to our terms and accept that
                        we are not responsible for any issues arising from its
                        use. For more details, please refer to the{" "}
                        <a href="#" style={{ color: "black" }}>
                            full disclaimer
                        </a>
                        .
                    </Typography>
                </Stack>
            </Stack>
        );
};

export default SignUp;
