import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import ProgressHeader from "./ProgressHeader";
import AITextField from "../AITextField";
import { Box, Button as MuiButton, Stack, Typography } from "@mui/material";
import ColoredIcon from "../ColoredIcon";
import DefaultIcon from "../../img/default_icon.svg";
import ImagePicker from "../ImagePicker";
import Color from "../../modules/Color";
import Brand from "../../api/Brand";
import Bg from "../Bg";
import { useCookies } from "react-cookie";
import BottomButton from "../BottomButton";
import { BUSINESS_NAME_PROMPT } from "../../utils/prompts";
import { getAppLanguage } from "../../utils/languageUtils";
import { uploadToCloudinary } from "../../utils/cloudinaryUtils";
import { useNavigate } from "react-router-dom";

const BrandCreation = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [business, setBusiness] = useState<string | undefined>();
    const [logo, setLogo] = useState<string | undefined>(
        JSON.parse(sessionStorage.getItem("brand") || "{}")?.logo
    );
    const [error, setError] = useState<string | null>(null);
    const [isNameValidating, setIsNameValidating] = useState(false);
    const [invalidInputMessage, setInvalidInputMessage] = useState<
        string | null
    >(null); // Temporary feedback

    const colors: Color[] = [
        new Color(241, 137, 174),
        new Color(194, 63, 134),
        new Color(193, 89, 195),
        new Color(68, 130, 224),
        new Color(66, 159, 179),
        new Color(36, 163, 148),
        new Color(76, 77, 220),
    ];

    const [cookies, setCookie] = useCookies(["theme_color"]);

    const initialColor = cookies.theme_color
        ? Color.fromHex(cookies.theme_color)
        : colors[0];
    const [selectedColor, setSelectedColor] = useState(initialColor);

    const generateInitialPrompt = () => {
        const businessType = sessionStorage.getItem("businessName") || "";
        return BUSINESS_NAME_PROMPT(businessType, getAppLanguage());
    };

    const putBrandIntoSessionStorage = useCallback(() => {
        const brandData = {
            name,
            address: name.toLocaleLowerCase(),
            color: selectedColor.toJSON(),
            logo,
            business,
        };
        sessionStorage.setItem("brand", JSON.stringify(brandData));
    }, [name, selectedColor, business, logo]);

    useEffect(() => {
        if (!cookies.theme_color)
            setCookie("theme_color", selectedColor.toHex());
    }, [cookies.theme_color, setCookie, selectedColor]);

    useEffect(() => {
        if (selectedColor.toHex() !== cookies.theme_color)
            setCookie("theme_color", selectedColor.toHex());
    }, [selectedColor, cookies.theme_color, setCookie]);

    useEffect(() => {
        const business_loaded = sessionStorage.getItem("business");
        if (business_loaded === null) {
            window.location.href = "/creation/business";
            return;
        }
        setBusiness(business_loaded!);

        const savedBrand = sessionStorage.getItem("brand");
        if (savedBrand) {
            const { name } = JSON.parse(savedBrand);
            setName(name);
            /*
            if (color) {
                const { r, g, b } = JSON.parse(color);
                setSelectedColor(new Color(r, g, b));
            }
            */
        }
        //sessionStorage.removeItem("brand");
    }, []);

    useEffect(() => {
        if (!error) putBrandIntoSessionStorage();
    }, [
        name,
        selectedColor,
        business,
        logo,
        error,
        putBrandIntoSessionStorage,
    ]);

    const validateName = useCallback(async (name: string) => {
        setIsNameValidating(true);
        try {
            const isFree = await Brand.validate(encodeURI(name.toLowerCase()));
            setError(
                isFree ? null : "Application with this name already exists"
            );
        } catch (err) {
            console.error("Error validating name:", err);
            setError("Failed to validate name.");
        } finally {
            setIsNameValidating(false);
        }
    }, []);

    const handleNameChange = (input: string) => {
        const sanitizedName = input.replace(/[^a-zA-Z0-9-]/g, "");

        if (input !== sanitizedName && sanitizedName === "") {
            // Show message only if field is empty and input gets sanitized
            setInvalidInputMessage(
                "Please use Latin letters, numbers, or dashes."
            );
            setTimeout(() => setInvalidInputMessage(null), 3000); // Hide message after 3 seconds
        }
        setName(sanitizedName);
        //setAddress
    };

    const onBlurHandler = useCallback(async () => {
        if (name.trim() === "") return;
        else {
            await validateName(name.trim());
        }
    }, [name, validateName]);

    const onCreate = useCallback(async () => {
        if (error || isNameValidating) {
            //alert("Application with this name already exists");
            return;
        }
        if (name.trim() === "") {
            //alert("Application name is empty");
            return;
        }

        if (business === undefined) navigate("/creation/business");
        else navigate("/creation/signup");
    }, [error, isNameValidating, business, name, navigate]);

    const saveAIGeneratedName = async (generatedName: string) => {
        handleNameChange(generatedName);
        await validateName(generatedName);
    };

    const onLogoUpload = async (base64Image?: string) => {
        if (!base64Image) return;

        try {
            const stagingUrl = await uploadToCloudinary(base64Image);
            if (stagingUrl) {
                setLogo(stagingUrl);
                console.log("Logo uploaded and staging URL set:", stagingUrl);
            } else {
                console.error("Failed to upload logo to Cloudinary.");
            }
        } catch (error) {
            console.error("Error during logo upload:", error);
        }
    };

    return (
        <Stack height="100vh" width="100%" alignItems="center">
            <Bg />
            <Header />
            <ProgressHeader step={1} title="Brand Your App" />
            <Block title="NAME YOUR APP">
                <AITextField
                    placeholder="Name"
                    sx={{
                        width: "85%",
                        alignSelf: "center",
                        border: error ? "1px solid red" : "1px solid #ccc",
                    }}
                    value={name}
                    setText={saveAIGeneratedName}
                    onChange={handleNameChange}
                    onBlur={onBlurHandler}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter") {
                            e.currentTarget.blur(); // Remove focus from the text field
                        }
                    }}
                    initialPrompt={generateInitialPrompt()}
                    contextType={"businessName"}
                />
                {error && (
                    <Typography
                        color="red"
                        fontSize="12px"
                        mt={1}
                        sx={{
                            width: "85%",
                            alignSelf: "center",
                        }}
                    >
                        {error}
                    </Typography>
                )}
                {invalidInputMessage && (
                    <Typography
                        color="orange"
                        fontSize="12px"
                        mt={1}
                        sx={{
                            width: "85%",
                            alignSelf: "center",
                        }}
                    >
                        {invalidInputMessage}
                    </Typography>
                )}
            </Block>
            <Block title="CHOOSE COLOR">
                <Stack
                    direction="row"
                    overflow="scroll"
                    paddingX={2}
                    spacing={1}
                >
                    {colors.map((color) => (
                        <ColorCircle
                            color={color}
                            isSelected={color.cmp(selectedColor)}
                            onClick={(color) => {
                                setSelectedColor(color);
                            }}
                        />
                    ))}
                </Stack>
            </Block>
            <Block title="CHANGE YOUR APP ICON">
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    {/* App Icon Display */}
                    <Stack spacing={1} alignItems="center">
                        <Box
                            width="96px"
                            height="96px"
                            borderRadius="22px"
                            bgcolor={selectedColor.toHex()}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {logo ? (
                                <Box
                                    width="100%"
                                    height="100%"
                                    borderRadius="22px"
                                    sx={{
                                        backgroundImage: `url(${logo})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                />
                            ) : (
                                <ColoredIcon
                                    icon={DefaultIcon}
                                    size={64}
                                    color="white"
                                />
                            )}
                        </Box>
                        <Typography
                            fontWeight={500}
                            fontSize="19px"
                            lineHeight="21.28px"
                            textAlign="center"
                        >
                            {name}
                        </Typography>
                    </Stack>
                    <ImagePicker onUpload={onLogoUpload} />
                </Stack>
            </Block>
            <Stack
                height="100%"
                width="100%"
                justifyContent="flex-end"
                alignItems="center"
                marginBottom={6}
                spacing={2}
            >
                <Stack alignItems="center">
                    <Typography>
                        Choose your colors and icon, and get moving!
                    </Typography>
                    <Typography>You can always tweak them later.</Typography>
                </Stack>
                <BottomButton
                    disabled={!name.trim() || !!error || isNameValidating}
                    onClick={onCreate}
                >
                    Create My App
                </BottomButton>
            </Stack>
        </Stack>
    );
};

const Block = (props: { title: String; children?: React.ReactNode }) => {
    const { title, children } = props;

    return (
        <Stack spacing={1} paddingY={2} width="100%">
            <Typography
                fontWeight={400}
                fontSize="12px"
                lineHeight="18px"
                paddingLeft={2}
                color="#1414149A"
                width="85%"
                alignSelf="center"
            >
                {title}
            </Typography>
            {children}
        </Stack>
    );
};

const ColorCircle = (props: {
    color: Color;
    isSelected?: boolean;
    onClick?: (color: Color) => void;
}) => {
    const { color, isSelected, onClick } = props;

    return (
        <MuiButton
            key={color.toHex()}
            onClick={() => onClick?.(color)}
            sx={{
                width: "60px",
                height: "60px",
                borderRadius: "36px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
            }}
        >
            <Box
                width="54px"
                height="54px"
                border="1px solid #0000001A"
                borderRadius="36px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    width={isSelected ? "42px" : "32px"}
                    height={isSelected ? "42px" : "32px"}
                    bgcolor={color.toHex()}
                    borderRadius="36px"
                ></Box>
            </Box>
        </MuiButton>
    );
};

export default BrandCreation;
