import React, { useState } from "react";
import {
    Box,
    Button,
    Typography,
    TextField,
    Stack,
    CircularProgress,
} from "@mui/material";
import GPTGenerator from "../modules/GPT";

interface ImagePromptModalProps {
    value?: string;
    onCancel: () => void;
    onSave: (val?: string) => void;
}

const ImagePromptModal = (props: ImagePromptModalProps) => {
    const { value, onCancel, onSave } = props;

    const [image, setImage] = useState<string | undefined>(value);
    const [prompt, setPrompt] = useState("");
    const [loading, setLoading] = useState(false);

    const generateImage = () => {
        if (prompt.trim().length === 0) return;
        setLoading(true);
        GPTGenerator.generateImage(prompt)
            .then((res) => {
                setImage("data:image/png;base64," + res.data[0].b64_json);
            })
            .catch((err) => {
                console.error(`Image generation error: ${err}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Stack
            position="absolute"
            top="50%"
            left="50%"
            width={300}
            bgcolor="background.paper"
            border="2px solid black"
            boxShadow={24}
            p={4}
            sx={{
                transform: "translate(-50%, -50%)",
            }}
            spacing={2}
        >
            <Typography variant="h6" component="h2">
                Image generator
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : image ? (
                <Box
                    width={300}
                    height={300}
                    sx={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                    }}
                />
            ) : null}
            <Stack spacing={2} direction="row" alignItems="center">
                <TextField
                    fullWidth
                    variant="outlined"
                    disabled={loading}
                    value={prompt}
                    label="Enter prompt..."
                    onChange={(e) => setPrompt(e.currentTarget.value)}
                />
                <Button
                    variant="contained"
                    color="success"
                    disabled={loading}
                    onClick={generateImage}
                    sx={{
                        paddingX: 3,
                    }}
                >
                    Generate
                </Button>
            </Stack>
            <Stack spacing={2} direction="row">
                <Button
                    variant="contained"
                    color="error"
                    disabled={loading}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    disabled={loading}
                    onClick={() => onSave(image)}
                >
                    Save
                </Button>
            </Stack>
        </Stack>
    );
};

export default ImagePromptModal;
