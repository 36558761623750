import React from "react";
import {
    Box,
    Button as MButton,
    SxProps,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import ColoredIcon from "./ColoredIcon";
import CheckMark from "../img/CheckMark.svg";

interface Props {
    selected?: boolean;
}

const Button = (props: Props) => {
    const theme = useTheme();

    const selected = props.selected ?? false;

    return (
        <Box
            width="22px"
            height="22px"
            bgcolor={theme.palette.primary.main}
            borderRadius="22px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {selected ? (
                <ColoredIcon size={16} icon={CheckMark} color="white" />
            ) : (
                <Box
                    width="18px"
                    height="18px"
                    bgcolor="white"
                    borderRadius="20px"
                />
            )}
        </Box>
    );
};

export default Button;
