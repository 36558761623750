import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typography, Stack, useTheme, ButtonBase } from "@mui/material";
import GPTGenerator from "../modules/GPT";
import OpenAI from "openai";
import AIIcon from "../img/AI.svg";
import ChevronLeft from "../img/Arrow_Left.svg";
import ChevronRight from "..//img/Arrow_Right.svg";
import TextField from "./TextField";
import ColoredIcon from "./ColoredIcon";
import Button from "./Button";
import { DefaultPrompt } from "../utils/prompts";
import { DisplayMessage } from "./AITextField";
import {
    AIContextType,
    transformMessageToPrompt,
} from "../utils/AIGenerationUtils";

interface Props {
    value?: string;
    onCancel: () => void;
    onSave: (val?: string) => void;
    history: OpenAI.Chat.Completions.ChatCompletionMessageParam[];
    setHistory: React.Dispatch<
        React.SetStateAction<
            OpenAI.Chat.Completions.ChatCompletionMessageParam[]
        >
    >;
    displayHistory: DisplayMessage[];
    setDisplayHistory: React.Dispatch<React.SetStateAction<DisplayMessage[]>>;
    initialPrompt?: DefaultPrompt;
    contextType: AIContextType;
}

const TextPromptModal = (props: Props) => {
    const {
        onCancel,
        onSave,
        history,
        setHistory,
        displayHistory,
        setDisplayHistory,
        initialPrompt,
        contextType,
    } = props;

    const theme = useTheme();

    const [message, setMessage] = useState<string | undefined>();
    const [userMessage, setUserMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const scrollDown = () =>
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    const handleUserMessageSubmit = () => {
        const trimmedMessage = userMessage.trim();
        if (trimmedMessage.length === 0) return;

        setDisplayHistory((prev) => [
            ...prev,
            { role: "user", content: trimmedMessage },
        ]);
        const techPrompt = transformMessageToPrompt(
            trimmedMessage,
            contextType
        );
        generateText(techPrompt);
        setUserMessage("");
    };

    const generateText = useCallback(
        (prompt: string) => {
            setLoading(true);
            console.log("Tech prompt: " + prompt);
            GPTGenerator.generateText(prompt, history)
                .then((res) => {
                    setHistory((prev) => [
                        ...prev,
                        { role: "user", content: prompt },
                        res.choices[0].message,
                    ]);
                    setDisplayHistory((prev) => [
                        ...prev,
                        res.choices[0].message as DisplayMessage,
                    ]);
                    setMessage(res.choices[0].message.content ?? undefined);
                    setUserMessage("");
                })
                .catch((err) => {
                    console.error(`Generation error: ${err}`);
                })
                .finally(() => {
                    scrollDown();
                    setLoading(false);
                });
        },
        [history, setHistory, displayHistory, setDisplayHistory, prompt]
    );

    useEffect(() => {
        if (history.length === 0 && initialPrompt) {
            setHistory([{ role: "user", content: initialPrompt.tech }]);
            setDisplayHistory([
                { role: "user", content: initialPrompt.display },
            ]);
            generateText(initialPrompt.tech);
        }
        if (history.length > 0) {
            const lastAssistantMessage = history
                .slice()
                .reverse()
                .find((msg) => msg.role === "assistant");
            if (lastAssistantMessage)
                setMessage(lastAssistantMessage!.content as string);
        }
    }, [history, displayHistory, initialPrompt, generateText]);

    return (
        <Stack
            position="absolute"
            bottom="0"
            left="50%"
            height="95%"
            width="100%"
            bgcolor="white"
            borderRadius="14px 14px 0px 0px"
            justifyContent="center"
            alignItems="center"
            sx={{
                transform: "translate(-50%, 0%)",
            }}
        >
            <Stack
                paddingY={1}
                spacing={2}
                width="90%"
                direction="row"
                alignItems="center"
            >
                <ButtonBase
                    disabled={loading}
                    onClick={onCancel}
                    sx={{ width: "38px", minWidth: "38px", height: "38px" }}
                >
                    <ColoredIcon icon={ChevronLeft} size="100%" color="black" />
                </ButtonBase>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    width="50px"
                    height="50px"
                    borderRadius="50px"
                    bgcolor={theme.palette.primary.main}
                >
                    <ColoredIcon icon={AIIcon} color="white" size="34px" />
                </Stack>
                <Typography fontWeight={500} fontSize="20px" lineHeight="30px">
                    AI Admin
                </Typography>
            </Stack>
            <Stack
                direction="column"
                height="100%"
                width="100%"
                overflow="scroll"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                paddingTop={2}
                bgcolor={theme.palette.primary.main + "0D"}
            >
                {displayHistory.map((x, index) => (
                    <MessageBox
                        key={index}
                        message={x}
                        className={
                            index === 0 ? "force-dynamic-translation" : ""
                        }
                    />
                ))}
                <div ref={messagesEndRef} style={{ marginTop: 10 }} />
            </Stack>
            <Stack width="90%" paddingY={2} spacing={2}>
                <Button disabled={loading} onClick={() => onSave(message)}>
                    Save
                </Button>
                <TextField
                    multiline
                    maxRows={5}
                    padding={2}
                    fullWidth
                    variant="outlined"
                    placeholder="Message"
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.currentTarget.value)}
                    icon={ChevronRight}
                    onClick={() => handleUserMessageSubmit()}
                    onKeyDown={(e) =>
                        e.key === "Enter" && handleUserMessageSubmit()
                    }
                />
            </Stack>
        </Stack>
    );
};

const MessageCloud = (props: {
    side: "left" | "center" | "right";
    content: string;
}) => {
    const theme = useTheme();

    const { side, content } = props;

    const borderRadius =
        side === "left"
            ? "14px 14px 14px 0"
            : side === "right"
            ? "14px 14px 0 14px"
            : "14px 14px 14px 14px";
    const bgcolor =
        theme.palette.primary.main + (side === "right" ? "FF" : "1D");
    const color = side === "right" ? "white" : "black";

    return (
        <Stack
            padding="10px 20px"
            bgcolor={bgcolor}
            borderRadius={borderRadius}
            maxWidth="75%"
        >
            <Typography
                fontWeight={400}
                fontSize="15px"
                lineHeight="19.5px"
                color={color}
                maxWidth="100%"
                sx={{
                    wordBreak: "break-word",
                }}
            >
                {content}
            </Typography>
        </Stack>
    );
};

const MessageBox = (props: { message: DisplayMessage; className?: string }) => {
    const { message, className } = props;

    const align = message.role === "assistant" ? "flex-start" : "flex-end";
    const side = message.role === "assistant" ? "left" : "right";
    const content = message.content?.toString() ?? "";

    return (
        <Stack
            width="90%"
            direction="row"
            justifyContent={align}
            className={className}
        >
            <Stack
                width="100%"
                direction="column"
                alignItems={align}
                spacing={0.5}
            >
                <MessageCloud side={side} content={content} />
            </Stack>
        </Stack>
    );
};

export default TextPromptModal;
