import axios from "axios";

/**
 * Upload an image to Cloudinary
 * @param base64Image - Base64 string of the image
 * @returns {Promise<string | null>} - Secure URL of the uploaded image
 */
export const uploadToCloudinary = async (
    base64Image: string
): Promise<string | null> => {
    console.log("Attempting to upload an image to Cloudinary...");

    const uniqueId =
        Date.now().toString(36) + Math.random().toString(36).substring(2);

    // Prepare the upload URL
    const uploadUrl = `https://api.cloudinary.com/v1_1/danbcslcx/image/upload`;

    try {
        // Prepare the form data
        const formData = new FormData();
        formData.append("file", base64Image);
        formData.append("upload_preset", "my-unsigned-preset");
        formData.append("public_id", uniqueId);
        formData.append("folder", "staging"); // Target folder: "staging"

        // Perform the upload
        const response = await axios.post(uploadUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.status === 200 && response.data.secure_url) {
            console.log(
                "Image uploaded successfully:",
                response.data.secure_url
            );
            return response.data.secure_url;
        } else {
            console.error(
                "Unexpected response from Cloudinary:",
                response.data
            );
            return null;
        }
    } catch (error) {
        console.error("Error uploading image to Cloudinary:", error);
        return null;
    }
};
