import React, { useEffect, useState } from "react";
import Header from "../Header";
import ProgressHeader from "./ProgressHeader";
import {
    Box,
    CircularProgress,
    Stack,
    Typography,
    useTheme,
    FormControl,
    OutlinedInput,
} from "@mui/material";
import ColoredIcon from "../ColoredIcon";
import Color from "../../modules/Color";
import DefaultIcon from "../../img/default_icon.svg";
import ImageLoader from "../../api/ImageLoader";
import Bg from "../Bg";

const EmailCheck = () => {
    const [code1, setCode1] = useState("");
    const [code2, setCode2] = useState("");
    const [code3, setCode3] = useState("");
    const [code4, setCode4] = useState("");
    const [brand, setBrand] = useState<
        | {
              name: string;
              address: string;
              color: Color;
              logo?: string;
              business: number;
          }
        | undefined
    >();

    useEffect(() => {
        const brand_loaded = sessionStorage.getItem("brand");
        if (brand_loaded === null) {
            window.location.href = "/creation/brand";
        }
        const brand_raw: {
            name: string;
            address: string;
            color: string;
            logo?: string;
            business: number;
        } = JSON.parse(brand_loaded!);
        setBrand({
            name: brand_raw.name,
            address: brand_raw.address,
            color: Color.fromJSON(brand_raw.color),
            logo: brand_raw.logo,
            business: brand_raw.business,
        });
    }, []);

    useEffect(() => {
        if (code1 !== "" && code2 !== "" && code3 !== "" && code4 !== "") {
            window.location.href = "/creation/completed";
        }
    }, [code1, code2, code3, code4]);

    if (!brand) return <CircularProgress />;
    return (
        <Stack height="100vh" width="100%" alignItems="center">
            <Bg />
            <Header />
            <ProgressHeader step={2} title="Create Your App Account" />
            <Stack width="80%" alignItems="center" spacing={8} marginTop={8}>
                <Stack spacing={1}>
                    <Box
                        width="96px"
                        height="96px"
                        borderRadius="22px"
                        bgcolor={brand.color.toHex()}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {brand!.logo ? (
                            <Box
                                width="100%"
                                height="100%"
                                borderRadius="22px"
                                sx={{
                                    backgroundImage: `url(${ImageLoader.format(
                                        brand!.logo
                                    )})`,
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                }}
                            />
                        ) : (
                            <ColoredIcon
                                icon={DefaultIcon}
                                size={64}
                                color="white"
                            />
                        )}
                    </Box>
                    <Typography
                        fontWeight={500}
                        fontSize="19px"
                        lineHeight="21.28px"
                        textAlign="center"
                    >
                        {brand!.name}
                    </Typography>
                </Stack>
                <Stack spacing={1}>
                    <Stack>
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                            textAlign="center"
                            sx={{ opacity: "80%" }}
                        >
                            Check your email for a four-digit code.
                        </Typography>
                        <Typography
                            fontWeight={400}
                            fontSize="14px"
                            lineHeight="18.2px"
                            textAlign="center"
                            sx={{ opacity: "80%" }}
                        >
                            Enter it below to comfirm
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <CodeField
                        id="code1"
                        value={code1}
                        setValue={(val) => {
                            setCode1(val);
                            setCode2("");
                            setCode3("");
                            setCode4("");
                        }}
                        onNext={() => document.getElementById("code2")?.focus()}
                    />
                    <CodeField
                        id="code2"
                        value={code2}
                        setValue={(val) => {
                            setCode2(val);
                            setCode3("");
                            setCode4("");
                        }}
                        onNext={() => document.getElementById("code3")?.focus()}
                    />
                    <CodeField
                        id="code3"
                        value={code3}
                        setValue={(val) => {
                            setCode3(val);
                            setCode4("");
                        }}
                        onNext={() => document.getElementById("code4")?.focus()}
                    />
                    <CodeField
                        id="code4"
                        value={code4}
                        setValue={(val) => {
                            setCode4(val);
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

const CodeField = (props: {
    id: string;
    value: string;
    setValue: (val: string) => void;
    onNext?: () => void;
}) => {
    const theme = useTheme();

    const { id, value, setValue, onNext } = props;

    return (
        <FormControl>
            <OutlinedInput
                id={id}
                value={value}
                onFocus={() => {
                    setValue("");
                }}
                onChange={(e) => {
                    if (
                        e.currentTarget.value[0] &&
                        Number(e.currentTarget.value[0])
                    ) {
                        setValue(e.currentTarget.value[0]);
                        onNext?.();
                    }
                }}
                sx={{
                    width: "42px",
                    height: "42px",
                    borderRadius: "14px",
                    padding: 0,
                    fontWeight: 400,
                    fontSize: "24px",
                    lineHeight: "28.8px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid",
                        borderColor: theme.palette.primary.main,
                        borderRadius: 4,
                    },
                }}
            />
        </FormControl>
    );
};

export default EmailCheck;
