import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import Button from "./Button";

interface BottomButtonProps {
    children: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    sticky?: boolean;
    sx?: SxProps<Theme>;
    width?: string;
    color?: "primary" | "secondary" | "outlined" | "transparent" | "black";
    variant?: "large" | "big" | "medium" | "small";
}

const BottomButton = ({
    children,
    onClick,
    disabled = false,
    sticky = false,
    sx,
    width = "80%",
    color = "primary",
    variant = "large",
}: BottomButtonProps) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <Box
            sx={{
                position: sticky ? "fixed" : "relative",
                bottom: sticky ? 0 : "auto",
                marginBottom: "28px !important", // To leave space for the language switcher temporary
                width: width,
                padding: "16px",
                display: "flex",
                justifyContent: "center",
                zIndex: sticky ? 1000 : "auto", // ensure it's above other content when sticky
                ...sx,
            }}
        >
            <Button
                onClick={handleClick}
                disabled={disabled}
                color={color}
                variant={variant}
                sx={{
                    width: "100%",
                }}
            >
                {children}
            </Button>
        </Box>
    );
};

export default BottomButton;
