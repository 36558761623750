import { Modal, SxProps, Theme } from "@mui/material";
import React, { useState } from "react";
import TextField from "./TextField";
import AIIcon from "../img/AI.svg";
import TextPromptModal from "./TextPromptModal";
import OpenAI from "openai";
import { DefaultPrompt } from "../utils/prompts";
import { AIContextType } from "../utils/AIGenerationUtils";

interface Props {
    variant?: "contained" | "outlined";
    placeholder?: string;
    value?: string;
    width?: number | string;
    fullWidth?: boolean;
    setText?: (val: string) => void;
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChange?: (val: string) => void;
    error?: boolean;
    sx?: SxProps<Theme>;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    initialPrompt: DefaultPrompt;
    contextType: AIContextType;
}

export interface DisplayMessage {
    role: "user" | "assistant";
    content: string;
}

const AITextField = React.forwardRef((props: Props, ref) => {
    const {
        variant,
        placeholder,
        value,
        width,
        fullWidth,
        setText,
        onFocus,
        onBlur,
        error,
        sx,
        onKeyDown,
        initialPrompt,
        contextType,
        onChange,
    } = props;

    const [showModal, setShowModal] = useState(false);
    const [history, setHistory] = useState<
        OpenAI.Chat.Completions.ChatCompletionMessageParam[]
    >([]);
    const [displayHistory, setDisplayHistory] = useState<DisplayMessage[]>([]);

    const showAI = () => setShowModal(true);

    const onCancel = () => setShowModal(false);
    const onSave = (val?: string) => {
        if (val) setText?.(val);
        onCancel();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        if (onChange) {
            onChange(inputValue); // Use the provided onChange handler
        } else {
            setText?.(inputValue); // Default behavior
        }
    };

    return (
        <>
            <TextField
                variant={variant}
                placeholder={placeholder}
                value={value}
                width={width}
                onChange={handleInputChange}
                sx={sx}
                icon={AIIcon}
                onClick={showAI}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                error={error}
                fullWidth={fullWidth}
            />
            <Modal open={showModal} onClose={() => {}}>
                <TextPromptModal
                    value={value}
                    onCancel={onCancel}
                    onSave={onSave}
                    history={history}
                    setHistory={setHistory}
                    displayHistory={displayHistory}
                    setDisplayHistory={setDisplayHistory}
                    initialPrompt={initialPrompt}
                    contextType={contextType}
                />
            </Modal>
        </>
    );
});

export default AITextField;
