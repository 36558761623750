import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import Button from "./Button";
import logo from "../img/Logo.svg";
import textLogo from "../img/TextLogo.svg";
import Bg from "./Bg";
import { useCookies } from "react-cookie";

const StartScreen = () => {
    const [cookies, , removeCookie] = useCookies(["theme_color"]);
    /*
    useEffect(() => {
        if (cookies.theme_color) removeCookie("theme_color");
    }, [cookies, removeCookie]);
    */

    return (
        <Stack height="100vh" justifyContent="center" alignItems="center">
            <Bg />
            <Stack>
                <img src={logo} alt="" />
                <img src={textLogo} alt="" />
            </Stack>

            <Button
                onClick={() => (window.location.href = "/creation/business")}
                sx={{
                    width: "260px",
                    marginTop: 8,
                    marginBottom: 2,
                }}
            >
                Let's Get Set Up!
            </Button>

            <Stack width="230px">
                <Typography
                    color="primary"
                    textAlign="center"
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="18.2px"
                >
                    Build your own perfect app, the easy and Bizapptor-iffic
                    way!
                </Typography>
            </Stack>
        </Stack>
    );
};

export default StartScreen;
