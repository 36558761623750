import React, { useCallback, useEffect } from "react";
import Header from "../Header";
import ProgressHeader from "./ProgressHeader";
import { Stack, Typography, useTheme } from "@mui/material";
import ColoredIcon from "../ColoredIcon";
import Checkmark from "../../img/Ok_flag.svg";
import Button from "../Button";
import { getEnvironment } from "../../utils/getEnvironment";
import Bg from "../Bg";
import { useCookies } from "react-cookie";

const environment = getEnvironment();

const Completed = () => {
    const theme = useTheme();

    const [cookies] = useCookies(["creation_uuid"]);

    useEffect(() => {
        if (!cookies.creation_uuid) {
            window.location.href = "/creation/signup";
        }
    }, [cookies]);

    const onNext = useCallback(() => {
        if (!cookies.creation_uuid) return;
        window.location.href =
            environment.admin_address + "/redirect/" + cookies.creation_uuid;
    }, [cookies]);

    return (
        <Stack height="100vh" width="100%" alignItems="center">
            <Bg />
            <Header />
            <ProgressHeader step={2} title="Create Your App Account" />
            <Stack width="80%" alignItems="center" spacing={8} marginTop={10}>
                <Typography
                    fontWeight={700}
                    fontSize="24px"
                    lineHeight="28.8px"
                    textAlign="center"
                    color="primary"
                >
                    Thanks for hanging in there!
                </Typography>
                <Stack alignItems="center">
                    <Typography
                        fontWeight={300}
                        fontSize="14px"
                        lineHeight="18.2px"
                        textAlign="center"
                        sx={{ opacity: "80%" }}
                    >
                        You've nailed creating your account.
                    </Typography>
                    <Typography
                        fontWeight={300}
                        fontSize="14px"
                        lineHeight="18.2px"
                        textAlign="center"
                        sx={{ opacity: "80%" }}
                    >
                        Now, let's jump into adding your services and setting up
                        your schedule!
                    </Typography>
                </Stack>
                <ColoredIcon
                    icon={Checkmark}
                    color={theme.palette.primary.main}
                    size="96px"
                    style={{
                        padding: "15px",
                    }}
                />
                <Button onClick={onNext}>Go to dashboard</Button>
            </Stack>
        </Stack>
    );
};

export default Completed;
