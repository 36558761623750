import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getEnvironment } from "./utils/getEnvironment";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/uk";
import { ThemeProvider, createTheme } from "@mui/material";
import router from "./router";
import { useCookies } from "react-cookie";
import useWeglot from "./hooks/useWeglot";

const environment = getEnvironment();

const Main = () => {
    useWeglot();

    const [cookies] = useCookies(["theme_color"]);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: cookies.theme_color ?? "#F189AE",
                    },
                    secondary: {
                        main: "#FFFFFF",
                    },
                    info: {
                        main: (cookies.theme_color ?? "#F189AE") + "33",
                    },
                    warning: {
                        main: "#141414",
                    },
                },
                shadows: [
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                    "none",
                ],
            }),
        [cookies]
    );

    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <>
        <GoogleOAuthProvider clientId={environment.google_client_id}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk">
                <React.StrictMode>
                    <Main />
                </React.StrictMode>
            </LocalizationProvider>
        </GoogleOAuthProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
