export type AIContextType =
    | "businessName"
    | "businessDescription"
    | "serviceDescription"
    | "profileDescription";

export const transformMessageToPrompt = (
    userMessage: string,
    contextType: AIContextType
) => {
    switch (contextType) {
        case "businessName":
            return `Based on the following user comment: "${userMessage}", suggest another business name. Please consider the business type, region of operation, and any other requirements discussed in the conversation. Return only the name with no additional text or explanation.`;
        default:
            return "";
    }
};
