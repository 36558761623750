import React from "react";
import { Stack } from "@mui/material";
import TextLogo from "../img/TextLogo.svg";

const Header = () => {
    return (
        <Stack
            width="100%"
            margin={1}
            alignItems="center"
            justifyContent="center"
        >
            <img
                src={TextLogo}
                alt="logo"
                style={{
                    width: "147px",
                    height: "34px",
                }}
            />
        </Stack>
    );
};

export default Header;
