import React, { useCallback, useEffect, useState } from "react";
import Business from "../../api/Business";
import { Stack, Typography, Button as MuiButton } from "@mui/material";
import Header from "../Header";
import Checkmark from "../Checkmark";
import Bg from "../Bg";
import BottomButton from "../BottomButton";
//import { useCookies } from "react-cookie";

const BusinessSelect = () => {
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [selected, setSelected] = useState<Business | undefined>();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        //sessionStorage.removeItem("business")
        const fetchBusinesses = async () => {
            try {
                const businessList = await Business.list();
                setBusinesses(businessList);

                const storedBusinessId = sessionStorage.getItem("business");
                if (storedBusinessId) {
                    const selectedBusiness = businessList.find(
                        (b: Business) => b.key === storedBusinessId
                    );
                    setSelected(selectedBusiness);
                }
            } catch (error) {
                console.error("Error fetching businesses:", error);
            }
        };

        fetchBusinesses();
    }, []);

    const onSave = useCallback(() => {
        if (selected) {
            console.log(selected);
            sessionStorage.setItem("business", selected.key);
            sessionStorage.setItem("businessName", selected.name);
            window.location.href = "/creation/brand";
        } else {
            alert("Select a service category");
        }
    }, [selected]);

    return (
        <Stack
            height={`${windowHeight}px`}
            //height="95vh"
            width="100%"
            alignItems="center"
            spacing={4}
            marginY={2}
        >
            <Bg />
            <Header />
            <Stack width="100%" alignItems="center" margin={2}>
                <Typography
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="18.2px"
                >
                    For more precise app customization, please
                </Typography>
                <Typography
                    fontWeight={600}
                    fontSize="18px"
                    lineHeight="23.4px"
                >
                    select the service category
                </Typography>
            </Stack>
            <Stack
                className="force-dynamic-translation"
                spacing={1.5}
                paddingBottom="calc(env(safe-area-inset-bottom) + 10vh)"
            >
                {businesses.map((x) => (
                    <Item
                        key={x.key}
                        isSelected={selected === x}
                        name={x.name}
                        onClick={() => {
                            setSelected(x);
                        }}
                    />
                ))}
            </Stack>

            <BottomButton
                sx={{
                    display: selected !== undefined ? "block" : "none",
                }}
                onClick={onSave}
                sticky={true}
            >
                Go to branding
            </BottomButton>
        </Stack>
    );
};

const Item = (props: {
    name: string;
    isSelected: boolean;
    onClick: () => void;
}) => {
    const { name, isSelected, onClick } = props;

    return (
        <MuiButton
            variant="text"
            onClick={onClick}
            sx={{
                padding: 0,
                textTransform: "none",
                justifyContent: "flex-start",
            }}
        >
            <Stack
                key={name}
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Checkmark selected={isSelected} />
                <Typography fontWeight={400} fontSize="18px" color="black">
                    {name}
                </Typography>
            </Stack>
        </MuiButton>
    );
};

export default BusinessSelect;
