import StartScreen from "./components/StartScreen";
import BusinessSelect from "./components/Registration/BusinessSelect";
import BrandCreation from "./components/Registration/BrandCreation";
import SignUp from "./components/Registration/SignUp";
import EmailCheck from "./components/Registration/EmailCheck";
import Completed from "./components/Registration/Completed";
import PageError from "./components/PageError";
import { createBrowserRouter, Navigate } from "react-router-dom";
import HealthCheckPage from "./components/HealthCheckPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/creation" replace />,
        errorElement: <PageError />,
    },
    {
        path: "/creation",
        element: <StartScreen />,
    },
    {
        path: "/creation/business",
        element: <BusinessSelect />,
    },
    {
        path: "/creation/brand",
        element: <BrandCreation />,
    },
    {
        path: "/creation/signup",
        element: <SignUp />,
    },
    {
        path: "/creation/email",
        element: <EmailCheck />,
    },
    {
        path: "/creation/completed",
        element: <Completed />,
    },
    {
        path: "/health/check",
        element: <HealthCheckPage />
    },
]);

export default router;
