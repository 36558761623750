import React from "react";
import { useRouteError } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

const PageError = () => {
    const err = useRouteError() as any;
    console.error(err);

    return (
        <Stack
            width="100%"
            paddingTop={20}
            direction="column"
            alignItems="center"
            spacing={2}
        >
            <Typography component="h1">Page error occured.</Typography>
            <Typography fontWeight="bold" component="h3">
                {err.statusText || err.message}
            </Typography>
        </Stack>
    );
};

export default PageError;
