import React from "react";
import {
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    SxProps,
    Theme,
    useTheme,
} from "@mui/material";
import ColoredIcon from "./ColoredIcon";

interface Props {
    placeholder?: string;
    value?: string;
    variant?: "contained" | "outlined" | "underlined";
    width?: number | string;
    height?: number | string;
    padding?: number | string;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    icon?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    buttons?: {
        icon: string;
        onClick: React.MouseEventHandler<HTMLButtonElement>;
    }[];
    error?: boolean;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
    type?: string;
    multiline?: boolean;
    maxRows?: number;
    disabled?: boolean;
    nextRef?: React.RefObject<HTMLInputElement>;
    onEnter?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextField = React.forwardRef((props: Props, ref) => {
    const theme = useTheme();

    const {
        placeholder,
        value,
        onChange,
        onFocus,
        onBlur,
        icon,
        width,
        height,
        padding,
        onClick,
        buttons,
        autoFocus,
        error,
        sx,
        fullWidth,
        type,
        multiline,
        maxRows,
        disabled,
        nextRef,
        onEnter,
        onKeyDown,
    } = props;

    const variant = props.variant ?? "contained";

    const bgColor =
        variant === "contained" ? theme.palette.primary.main + "0D" : "#FFFFFF";
    const border =
        variant === "contained" ? "#FFFFFF00" : theme.palette.primary.main;
    const borderRadius = variant === "underlined" ? "0" : "14px";
    const borderWidth = variant === "underlined" ? "0 0 1px 0" : "1px";

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            if (nextRef && nextRef.current) nextRef.current.focus();
            else if (onEnter) onEnter();
            else if (onKeyDown) onKeyDown(e);
        } else if (onKeyDown) onKeyDown(e);
    };

    return (
        <FormControl fullWidth={fullWidth}>
            <OutlinedInput
                inputRef={ref}
                multiline={multiline}
                maxRows={maxRows}
                type={type}
                fullWidth={fullWidth}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
                endAdornment={
                    buttons && buttons.length > 0 ? (
                        buttons.map((x) => (
                            <InputAdornment position="end" sx={{ margin: 0 }}>
                                <IconButton onClick={x.onClick}>
                                    <ColoredIcon
                                        icon={x.icon}
                                        size="32px"
                                        color={theme.palette.primary.main}
                                    />
                                </IconButton>
                            </InputAdornment>
                        ))
                    ) : icon ? (
                        <InputAdornment position="end">
                            <IconButton onClick={onClick}>
                                <ColoredIcon
                                    icon={icon}
                                    size="32px"
                                    color={theme.palette.primary.main}
                                />
                            </IconButton>
                        </InputAdornment>
                    ) : undefined
                }
                error={error}
                disabled={disabled}
                sx={{
                    width,
                    minHeight: height ?? "42px",
                    borderRadius: borderRadius,
                    padding: padding ?? 0,
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    backgroundColor: bgColor,
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: borderWidth,
                        borderStyle: "solid",
                        borderColor: border,
                    },
                    ...sx,
                }}
            />
        </FormControl>
    );
});

export default TextField;
