import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";
import Color from "../modules/Color";

const environment = getEnvironment();

export default class Brand {
    public static readonly validate = async (address: string) =>
        (await axios
            .get(environment.api_url + "/brand/validate/" + address)
            .then((res) => {
                return res.status === 200 ? res.data : false;
            })
            .catch(console.error)) as boolean;

    public static readonly count = async () =>
        (
            await axios
                .get(environment.api_url + "/business/count")
                .then((res) => (res.status === 200 ? res.data : undefined))
                .catch(console.error)
        )?.count as number | undefined;

    public static readonly register = async (
        uuid: string,
        name: string,
        address: string,
        color: Color,
        description: string,
        business_id: string,
        logo?: string
    ) =>
        (await axios
            .post(
                environment.api_url + "/brand",
                {
                    name,
                    address,
                    colorRed: color.r,
                    colorGreen: color.g,
                    colorBlue: color.b,
                    description,
                    business: business_id,
                    logo,
                },
                {
                    headers: {
                        "User-Token": uuid,
                    },
                }
            )
            .then((res) => (res.status === 200 ? res.data : undefined))
            .catch(console.error)) as Brand | undefined;
}
