import { useLayoutEffect, useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";

interface Props {
    step: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    title: string;
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = () =>
            setSize([window.innerWidth, window.innerHeight]);
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

const ProgressHeader = (props: Props) => {
    const theme = useTheme();

    const { title, step } = props;

    const minutesLeft = 8 - step;

    const [width] = useWindowSize();

    return (
        <Stack width="100%" alignItems="center">
            <Stack
                width="100%"
                height="48px"
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <Box
                    width={(width / 8) * step}
                    height="4px"
                    bgcolor={theme.palette.primary.main}
                />
            </Stack>
            <Stack
                position="absolute"
                width="90%"
                height="48px"
                justifyContent="center"
                alignItems="flex-start"
            >
                <Stack
                    justifyContent="center"
                    alignItems="flex-end"
                    direction="row"
                    spacing={0.5}
                >
                    <Typography
                        fontWeight={500}
                        fontSize="16px"
                        textAlign="center"
                        lineHeight="17.28px"
                        color="#1A1A1A"
                    >
                        {minutesLeft === 0 ? "100%" : `~${minutesLeft}`}
                    </Typography>
                    <Typography
                        fontWeight={500}
                        fontSize="10px"
                        textAlign="center"
                        lineHeight="10.8px"
                        color="#1A1A1A"
                    >
                        {minutesLeft === 0 ? "" : "min"}
                    </Typography>
                </Stack>
            </Stack>
            <Stack position="absolute" height="48px" justifyContent="center">
                <Typography
                    fontWeight={500}
                    fontSize="16px"
                    textAlign="center"
                    lineHeight="24px"
                >
                    {title}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default ProgressHeader;
