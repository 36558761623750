import axios from "axios";
import { getEnvironment } from "../utils/getEnvironment";

const environment = getEnvironment();

export default class Business {
    key: string;
    name: string;
    description: string;

    constructor(key: string, name: string, description: string) {
        this.key = key;
        this.name = name;
        this.description = description;
    }

    public static readonly list = async (start?: number, count?: number) =>
        (await axios
            .get(environment.api_url + "/business/list", {
                params: { start, count },
            })
            .then((res) => (res.status === 200 ? res.data : []))
            .catch(console.error)) as Business[];
}
